import { computed, EventEmitter, inject, Injectable, signal } from '@angular/core';
import { createScanRenderer, PickPointResult } from '@wr/scan-renderer';
import { ScanViewerAdapter } from '@pages/building-scan-detail-page/scan-viewer-adapter';
import { BuildingScanViewerDetailDto, BuildingScanService, PointOfInterestDto, PointOfInterestService, Vector3, ShareLinkService } from '@artemis-software/wr-api';
import { DialogService } from '@services/dialog-service';
import { firstValueFrom } from 'rxjs';
import { loader } from '@/utils/signals/loader';
import type { PickPointOptions } from '@wr/scan-renderer/dist/state/PickPointState';
import { shareLink } from '@pages/share-link-page/share-link-page.component';

export interface CreatePointOfInterestOptions {
  name: string;
  panoramaId: string;
  position: Vector3;
  buildingEquipmentId?: string;
  maintenanceTimerId?: string;
}


@Injectable()
export class ScanViewerService {
  private readonly scanService = inject(BuildingScanService);
  private readonly pointOfInterestService = inject(PointOfInterestService);
  private readonly dialogService = inject(DialogService);
  private readonly shareLinkService = inject(ShareLinkService);

  private readonly scan = signal<BuildingScanViewerDetailDto | null>(null);
  private adapter?: ScanViewerAdapter;

  private scanViewer?: ReturnType<typeof createScanRenderer>;

  readonly pointOfInterestAdded = new EventEmitter<PointOfInterestDto>();

  private readonly shareLink = inject(shareLink, { optional: true });

  mount(container: HTMLElement, scan: BuildingScanViewerDetailDto, readonly: boolean) {
    if (!scan)
      throw new Error('Scan is required');

    if (this.scanViewer)
      this.unmount();

    this.scan.set(scan);

    this.scanViewer = createScanRenderer(
      this.adapter = new ScanViewerAdapter(
        scan,
        this.pointOfInterestService,
        this.dialogService,
        readonly,
        this.shareLinkService,
        this.shareLink,
      ),
      container
    );

    this.adapter.pointOfInterestedChanged.subscribe(poi => {
      this.scan.update(scan => {
        if (scan === null)
          return null;

        const pointsOfInterest = [...(scan?.pointsOfInterest ?? [])];

        const index = pointsOfInterest.findIndex(it => it.id === poi.id);
        if (index >= 0)
          pointsOfInterest[index] = poi;
        else
          pointsOfInterest.push(poi);

        return {
          ...scan,
          pointsOfInterest,
        };
      });
    });
  }

  unmount() {
    this.scanViewer?.unmount();
    this.scanViewer = undefined;
    this.scan.set(null);
    this.adapter = undefined;
  }

  private readonly scanId = computed(() => this.scan()?.id);

  async createPointOfInterest(options: CreatePointOfInterestOptions) {
    const pointOfInterest = await firstValueFrom(this.pointOfInterestService.create({
      scanId: this.adapter!.scan.id,
      name: options.name,
      position: options.position,
      buildingEquipmentId: options?.buildingEquipmentId,
      maintenanceTimerId: options?.maintenanceTimerId,
      panoramaId: options.panoramaId,
    }));

    this.scanViewer?.addPointOfInterest(pointOfInterest);

    this.scan.set({
      ...this.scan()!,
      pointsOfInterest: [...this.scan()!.pointsOfInterest, pointOfInterest],
      buildingEquipments: [...this.scan()!.buildingEquipments.map(it => {
        if (options.buildingEquipmentId === it.id)
          return {
            ...it,
            associatedPointsOfInterest: [...it.associatedPointsOfInterest, pointOfInterest],
          };

        return {
          ...it,
          maintenanceTimers: [...it.maintenanceTimers.map(mt => {
            if (options.maintenanceTimerId === mt.id)
              return {
                ...mt,
                associatedPointOfInterest: pointOfInterest,
              };
            return mt;
          })],
        };
      })],
    });
  }

  async pickPosition(options?: PickPointOptions): Promise<PickPointResult | null> {
    if (!this.scanViewer)
      return null;

    return this.scanViewer.pickPosition(options);
  }

  jumpToPointOfInterest(pointOfInterest: PointOfInterestDto) {
    this.scanViewer?.bringIntoView(pointOfInterest.position, pointOfInterest.panoramaId);
  }

  readonly pointsOfInterestsLoading = loader();

  readonly pointsOfInterest = computed(() => this.scan()?.pointsOfInterest ?? []);

  readonly buildingEquipmentsLoading = loader();

  readonly buildingEquipments = computed(() => this.scan()?.buildingEquipments ?? []);
}
