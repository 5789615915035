/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManagerDetailsDto } from './managerDetailsDto';
import { Coordinates } from './coordinates';
import { ConditionDto } from './conditionDto';
import { OrganisationItemDto } from './organisationItemDto';
import { AttachmentDetailDto } from './attachmentDetailDto';
import { BuildingSectionItemDto } from './buildingSectionItemDto';
import { UserItemDto } from './userItemDto';


export interface BuildingDetailDto { 
    id: string;
    name: string;
    buildingClass: BuildingDetailDto.BuildingClassEnum;
    floorCountWithoutCellar: string;
    undergroundGarageCount: number;
    garageCount: number;
    convertedAttic: BuildingDetailDto.ConvertedAtticEnum;
    buildingType: BuildingDetailDto.BuildingTypeEnum;
    organisation: OrganisationItemDto;
    attachments: Array<AttachmentDetailDto>;
    propertyManager?: UserItemDto;
    embeddedPropertyManager?: ManagerDetailsDto;
    propertyCaretaker: string;
    assistant: string;
    internalNumber: string;
    externalNumber: string;
    area: number;
    entryCount: number;
    businesses: string;
    owner: string;
    propertyKey: string;
    keySafeWithPosition: string;
    codes: string;
    playGround: string;
    heatingSystem: string;
    sectionText: string;
    sections: Array<BuildingSectionItemDto>;
    otherBuildingType?: string;
    otherBuildingClass?: string;
    buildingCondition: ConditionDto;
    coordinates?: Coordinates;
    note?: string;
    sendMaintenanceNotifications: boolean;
    flatCount: number;
}
export namespace BuildingDetailDto {
    export type BuildingClassEnum = 'ONE' | 'TWO' | 'THREE' | 'FOUR' | 'FIVE' | 'TERRACED_HOUSES' | 'HIGHRISE' | 'OTHER';
    export const BuildingClassEnum = {
        One: 'ONE' as BuildingClassEnum,
        Two: 'TWO' as BuildingClassEnum,
        Three: 'THREE' as BuildingClassEnum,
        Four: 'FOUR' as BuildingClassEnum,
        Five: 'FIVE' as BuildingClassEnum,
        TerracedHouses: 'TERRACED_HOUSES' as BuildingClassEnum,
        Highrise: 'HIGHRISE' as BuildingClassEnum,
        Other: 'OTHER' as BuildingClassEnum
    };
    export type ConvertedAtticEnum = 'CONVERTED_ATTIC' | 'NO_CONVERTED_ATTIC' | 'PARTIALLY';
    export const ConvertedAtticEnum = {
        ConvertedAttic: 'CONVERTED_ATTIC' as ConvertedAtticEnum,
        NoConvertedAttic: 'NO_CONVERTED_ATTIC' as ConvertedAtticEnum,
        Partially: 'PARTIALLY' as ConvertedAtticEnum
    };
    export type BuildingTypeEnum = 'RESIDENTIAL_BUILDING' | 'RESIDENTIAL_BUILDING_WITH_BUSINESS' | 'SCHOOL' | 'OTHER';
    export const BuildingTypeEnum = {
        ResidentialBuilding: 'RESIDENTIAL_BUILDING' as BuildingTypeEnum,
        ResidentialBuildingWithBusiness: 'RESIDENTIAL_BUILDING_WITH_BUSINESS' as BuildingTypeEnum,
        School: 'SCHOOL' as BuildingTypeEnum,
        Other: 'OTHER' as BuildingTypeEnum
    };
}


